import { useToast } from '@chakra-ui/react';
import { Freguesia, IpeError, IpeUser, Municipio, Pedido, PedidoContratoEventual, PedidoPontoLuz, PedidoPoste, Permission } from '@models/models/models.types';
import { PostgrestResponse } from '@supabase/supabase-js';
import { supabaseBrowserClient } from '@utils/client';
import { Database } from '@utils/database.types';
import { getTokensFromUrl, pathTo } from '@utils/router';
import { inviteEmail, sanitizeFilename } from '@utils/utils';
import assert from 'assert';
import axios from 'axios';
import { SuccessApiResponse } from 'next-api-handler';
import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';
import { ISupabaseContext, SupabaseProps, VIEWS } from './supabase.types';
import { ipeFile } from '@components/organisms/enviar-documentos/enviar-documentos';
import { createBrowserClient } from '@supabase/ssr';
import { setUser } from '@sentry/nextjs';
export const SupabaseContext = createContext<ISupabaseContext>({} as ISupabaseContext);
export const SupabaseProvider = ({
  ...props
}: SupabaseProps) => {
  const supabase = supabaseBrowserClient();
  const [view] = useState(VIEWS.SIGN_IN);
  const [error, setError] = useState<IpeError | null>(null);
  const [ipeUser, setIpeUser] = useState<IpeUser | null>(props.user ?? null);
  const toast = useToast();
  const router = useRouter();
  useEffect(() => {
    const {
      data: {
        subscription
      }
    } = supabase.auth.onAuthStateChange(async (event, _) => {
      switch (event) {
        case 'SIGNED_IN':
          // router.push(pathTo().home())
          break;
        case 'SIGNED_OUT':
          setUser(null);
          break;
      }
    });
    (async () => {
      if (router.asPath.includes('#')) {
        const {
          tokenType,
          accessToken,
          refreshToken
        } = getTokensFromUrl(router.asPath);
        await axios.post(pathTo().api().callback(), {
          tokenType,
          accessToken,
          refreshToken
        });
        const {
          data: {
            user
          },
          error: getUserError
        } = await supabase.auth.getUser();
        if (getUserError) {
          console.error('getUserError', getUserError);
        }
        if (user == null) {
          throw Error('user is null');
        }
        const {
          data: profile
        } = await supabase.from('profiles').select('*').eq('user_id', user.id).single();
        const {
          data: user_roles
        } = await supabase.from('user_roles').select('*').eq('user_id', user.id);
        const roles = user_roles?.map(ur => ur.role);
        const {
          data
        } = await supabase.from('role_permissions').select('permission').in('role', roles ?? []);
        const permissions = data?.map(item => item.permission as Permission);
        const _ipeUser = {
          ...user,
          user_roles: user_roles,
          profile: profile,
          permissions: permissions
        } as IpeUser;
        setIpeUser(_ipeUser);
        if (tokenType == 'recovery') {
          router.push(pathTo().perfil().definirPalavraPasse());
        } else {
          router.push(pathTo().home());
        }
        return;
      }
      const {
        data: {
          user
        }
      } = await supabase.auth.getUser();
      if (user == null) {
        return;
      }
      const {
        data: profile
      } = await supabase.from('profiles').select('*').eq('user_id', user.id).single();
      const {
        data: user_roles
      } = await supabase.from('user_roles').select('*').eq('user_id', user.id);
      const roles = user_roles?.map(ur => ur.role);
      const {
        data
      } = await supabase.from('role_permissions').select('permission').in('role', roles ?? []);
      const permissions = data?.map(item => item.permission as Permission);
      const _ipeUser = {
        ...user,
        user_roles: user_roles,
        profile: profile,
        permissions: permissions
      } as IpeUser;
      setIpeUser(_ipeUser);
    })();
    return () => {
      subscription.unsubscribe();
    };
  }, [router, router.asPath, supabase]);
  async function createPedido(pedido: PedidoPontoLuz | PedidoPoste | PedidoContratoEventual): Promise<PostgrestResponse<Pedido>> {
    const {
      files,
      ...rest
    } = pedido as PedidoPontoLuz | PedidoPoste;
    if (ipeUser?.profile?.junta_freguesia_id) {
      rest.junta_freguesia_id = ipeUser.profile.junta_freguesia_id;
    }
    if (rest.junta_freguesia_id == '') {
      rest.junta_freguesia_id = null;
    }
    if (rest.junta_freguesia == '') {
      rest.junta_freguesia = null;
    }
    const res = await supabase.from('pedidos').insert(rest).select('*');
    if (res.error) {
      console.error('pedidos error', error);
      setError({
        ...res.error,
        title: 'Erro ao criar novo pedido',
        name: 'eita'
      });
    }
    if (res.data) {
      if (files && files.length > 0) {
        await uploadAnexoPedido(res.data[0], files);
      }
      toast({
        status: 'success',
        title: 'Pedido criado com sucesso',
        description: `O pedido ${res.data[0]?.codigo_pedido} foi criado com sucesso`,
        position: 'top'
      });
    }
    return res;
  }
  async function generateNextPedidoCode(): Promise<string> {
    const {
      error,
      data
    } = await supabase.rpc('get_next_pedido_code');
    if (error) {
      console.error(error);
      return '';
    }
    if (data !== null) {
      const nPedido = data;
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const code = `${year}.${month}#${nPedido}`;
      return code;
    }
    return '';
  }
  async function uploadAnexoPedido(pedido: Pedido, files: ipeFile[]) {
    const promises = files.map(async file => {
      const sanitizedFileName = sanitizeFilename(file.name);
      return await supabase.storage.from('pedidos').upload(`${pedido.id}/${file.category}/${sanitizedFileName}`, file);
    });
    const res = await Promise.all(promises);
    res.forEach(({
      error
    }) => {
      if (error) {
        console.error('failed to upload', error);
        setError({
          ...error,
          title: 'Erro ao submeter documento',
          name: 'Erro ao submeter documento'
        });
        return;
      }
    });
  }
  async function fetchFreguesias(municipio?: string | null | undefined) {
    let freguesias: Freguesia[] = [];
    if (municipio) {
      const {
        data,
        error
      } = await supabase.from('juntas_freguesias').select('*').eq('municipio', municipio).order('nome', {
        ascending: true
      });
      if (error) {
        console.error(error);
      }
      if (data) freguesias = data;
    } else {
      let query = supabase.from('juntas_freguesias').select('*');
      const roles = ipeUser?.user_roles?.map(ur => ur.role);
      if (roles?.includes('administrador')) {
        query = query.eq('municipio_id', ipeUser!.profile!.municipio_id!);
      }
      const {
        data,
        error
      } = await query.order('nome', {
        ascending: true
      });
      if (error) {
        console.error(error);
      }
      if (data) freguesias = data;
    }
    return freguesias;
  }

  // TODO: this function needs to be refactored or removed
  async function inviteFreguesias(freguesias: Freguesia[]) {
    await Promise.all(freguesias.map(f => f.email).filter(f => f !== null && f !== '').map(async email => {
      //FIXME: passing the SERVICE_KEY here is not ideal
      const serviceKey = process.env.NEXT_PUBLIC_SUPABASE_SERVICE_KEY;
      const supabase = createBrowserClient<Database>(process.env.NEXT_PUBLIC_SUPABASE_URL!, serviceKey!);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const {
        data,
        error
      } = await supabase.auth.admin.inviteUserByEmail(email!);
      if (error) {
        console.error(error);
      }
      return data;
    }));
    return;
  }
  async function updateEmailFreguesia({
    id,
    email
  }: Freguesia) {
    const updates = {
      id,
      email,
      updated_at: new Date().toISOString()
    };
    const res = await supabase.from('juntas_freguesias').update(updates).eq('id', id);
    if (res.error) throw res.error;
  }
  async function updateMuncipio(municipio: Municipio) {
    const updates = {
      ...municipio,
      updated_at: new Date().toISOString()
    };
    const {
      error
    } = await supabase.from('municipios').update(updates).eq('id', municipio.id);
    if (error) {
      console.error(error);
    }
  }
  async function getUserLocation(freguesia_id: string | null | undefined, municipio_id: string | null | undefined) {
    let municipio: string | null | undefined = '';
    let freguesia: string | null | undefined = '';
    if (freguesia_id) {
      const {
        error,
        data
      } = await supabase.from('juntas_freguesias').select('nome, municipio').eq('id', freguesia_id).single();
      if (error) {
        setError({
          ...error,
          title: 'Erro ao buscar localização do usuário',
          name: ''
        });
        return;
      }
      municipio = data?.municipio;
      freguesia = data?.nome;
    }
    if (municipio_id) {
      const {
        error,
        data
      } = await supabase.from('municipios').select('nome').eq('id', municipio_id).single();
      if (error) {
        setError({
          ...error,
          title: 'Erro ao buscar localização do usuário',
          name: ''
        });
        return;
      }
      municipio = data?.nome;
    }
    return {
      municipio,
      freguesia
    };
  }
  async function inviteFreguesia({
    id,
    email
  }: Pick<Freguesia, 'id' | 'email'>) {
    if (email == null) return;
    const {
      data,
      error
    } = await inviteEmail(ipeUser!.email!, email, 'freguesia');
    if (error) {
      setError({
        ...error,
        title: 'Erro ao convidar freguesia',
        message: 'O convite já foi enviado anteriormente.'
      });
      return;
    }
    const {
      data: profile,
      error: profileError
    } = await supabase.from('profiles').select('*').eq('user_id', data!.user!.id).single();
    if (profileError) {
      console.error(profileError);
    }
    if (profile) {
      profile.junta_freguesia_id = id;
      const {
        error
      } = await supabase.from('profiles').update(profile).eq('user_id', profile.user_id);
      if (error) {
        console.error(error);
      }
    }
    await supabase.from('juntas_freguesias').update({
      convidado: true
    }).eq('id', id);
  }
  async function inviteMunicipio(municipio: Pick<Municipio, 'id' | 'email'>) {
    const {
      id,
      email
    } = municipio;
    if (email == null) return;
    const {
      data,
      error
    } = await inviteEmail(ipeUser!.email!, email, 'municipio');
    if (error) {
      console.error(error);
      if (error.message.includes('duplicate key')) {
        setError({
          ...error,
          title: 'Error ao convidar município',
          message: 'O convite já foi enviado anteriormente'
        });
      } else {
        setError({
          ...error,
          message: '',
          title: 'Erro ao convidar município'
        });
      }
      return;
    }
    assert(data);
    const {
      data: profile,
      error: profileError
    } = await supabase.from('profiles').select('*').eq('user_id', data.user.id).single();
    if (profileError) {
      console.error(profileError);
      setError({
        ...profileError,
        name: '',
        title: 'Error ao convidar município'
      });
    }
    if (profile) {
      profile.municipio_id = id;
      const {
        error
      } = await supabase.from('profiles').update(profile).eq('user_id', profile.user_id);
      if (error) {
        console.error(error);
        setError({
          ...error,
          name: '',
          title: 'Error ao convidar município'
        });
      }
    }
  }
  async function inviteTecnico({
    municipioId,
    email
  }: {
    municipioId?: string;
    email: string;
  }) {
    const {
      data,
      error
    } = await inviteEmail(ipeUser!.email!, email, 'tecnico');
    if (error) {
      if (error.message.includes('duplicate key')) {
        setError({
          ...error,
          title: 'Error ao convidar técnico',
          message: `Um convite já foi enviado anteriormente para este email ${email}`
        });
      } else {
        setError({
          ...error,
          message: '',
          title: 'Erro ao convidar técnico'
        });
      }
      throw error;
    }
    assert(data);
    const {
      data: profile,
      error: profileError
    } = await supabase.from('profiles').select('*').eq('user_id', data.user.id).single();
    if (profileError) {
      console.error(profileError);
      setError({
        ...profileError,
        name: '',
        title: 'Error ao convidar técnico'
      });
    }
    if (profile) {
      const role = ipeUser?.user_roles?.map(ur => ur.role)[0];
      const id = role === 'administrador' ? ipeUser!.profile!.municipio_id : municipioId;
      profile.municipio_id = id!;
      const {
        error
      } = await supabase.from('profiles').update(profile).eq('user_id', profile.user_id);
      if (error) {
        console.error(error);
        setError({
          ...error,
          name: '',
          title: 'Error ao convidar técnico'
        });
      }
    }
  }
  async function updatePedido({
    files,
    metaFiles,
    ...pedido
  }: PedidoPoste | PedidoContratoEventual | PedidoPontoLuz) {
    console.log(metaFiles, 'metaFiles');
    if (pedido.junta_freguesia == '') {
      pedido.junta_freguesia = null;
    }
    if (pedido.junta_freguesia_id == '') {
      pedido.junta_freguesia_id = null;
    }
    const {
      error
    } = await supabase.from('pedidos').upsert(pedido);
    if (error) {
      console.error(error);
      setError({
        ...error,
        title: 'Error ao atualizar pedido',
        name: 'Erro ao atualizar pedido'
      });
      return;
    }
    if (files) {
      await uploadAnexoPedido(pedido, files);
    }
    const toDelete = metaFiles?.filter(file => file.isDeleted === true) ?? [];
    if (toDelete.length > 0) {
      const deletePaths = toDelete.map(file => {
        let url = `${pedido.id}`;
        if (file.category) {
          url += `/${file.category}/${file.name}`;
        } else {
          url += `/${file.name}`;
        }
        return url;
      });
      const {
        error
      } = await supabase.storage.from('pedidos').remove(deletePaths);
      if (error) {
        console.error(error);
        setError({
          ...error,
          title: 'Error ao apagar anexos removidos',
          name: 'Erro ao apagar anexos removidos'
        });
      }
    }
    const toMove = metaFiles?.filter(({
      newCategory
    }) => newCategory) ?? [];
    if (toMove.length > 0) {
      await Promise.all(toMove.map(async file => {
        const toPath = `${pedido.id}/${file.newCategory}/${file.name}`;
        let fromPath = `${pedido.id}`;
        if (file.category) {
          fromPath += `/${file.category}/${file.name}`;
        } else {
          fromPath += `/${file.name}`;
        }
        const {
          error
        } = await supabase.storage.from('pedidos').move(fromPath, toPath);
        if (error) {
          console.error(error);
          setError({
            ...error,
            title: 'Error ao apagar anexos removidos',
            name: 'Erro ao apagar anexos removidos'
          });
        }
      }));
    }
    toast({
      status: 'success',
      title: `O pedido ${pedido.codigo_pedido} foi alterado com sucesso`,
      position: 'top'
    });
  }
  function userCan(requiredPermission: Permission) {
    const givenPermissions = ipeUser?.permissions ?? [];
    return givenPermissions.includes(requiredPermission);
  }
  async function fetchMunicipios() {
    const {
      data: municipios,
      error
    } = await supabase.from('municipios').select('*');
    if (error) {
      console.error(error);
    }
    return municipios ?? [];
  }
  async function fetchUsers() {
    try {
      const {
        data: {
          data
        }
      } = await axios.get<SuccessApiResponse<IpeUser[]>>(pathTo().api().admin().users());
      return data;
    } catch (error) {
      console.error('fetchUsersError', error);
    }
    return [];
  }
  return <SupabaseContext.Provider value={{
    user: ipeUser,
    view,
    actions: {
      createPedido,
      generateNextPedidoCode,
      uploadAnexoPedido,
      fetchFreguesias,
      inviteFreguesias,
      inviteFreguesia,
      updateEmailFreguesia,
      updatePedido,
      userCan: userCan,
      fetchMunicipios,
      inviteMunicipio,
      updateMuncipio,
      fetchUsers,
      inviteTecnico,
      getUserLocation
    },
    error
  }} {...props} data-sentry-element="unknown" data-sentry-component="SupabaseProvider" data-sentry-source-file="supabase.service.tsx" />;
};